import React, {Component} from 'react';
import {Route, Switch} from 'react-router';
import {Home} from "./views/Home";
import "./assets/css/app.css";

export class App extends Component {

  render() {
    return (
      <Switch>
        <Route exact path='/' component={Home} />
      </Switch>
    );
  }
}

export default App;
