import React, {Component} from 'react';
import {Container} from "reactstrap";
import "../assets/css/home.css";

export class Home extends Component {

  render() {
    return (
      <Container fluid className="home">
        <p className="title">LOVE<br/>NEKO</p>
        <p className="under-construction"><strong>UNDER CONSTRUCTION</strong></p>
      </Container>
    )
  }
}